@import './variable.scss';

$primary-color: map_get($base-colors, 'primary-color');
$secondary-color: map_get($base-colors, 'text-color');
$light_gray: map_get($font-colors, 'light-gray');
$dark_gray: map_get($font-colors, 'dark-gray');
$white: map_get($font-colors, 'white');
$bombay: map_get($font-colors, 'bombay');

.main-footer-area {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto; /* Footer height */
  z-index: 1;
  background-color: $primary-color;
  font-family: 'Trebuchet MS';

  .footer-grid-container {
    width: 80%;
    margin: 0 auto;

    .contact-info {
      .logo {
        max-width: 70%;
        height: auto;
      }

      .initials {
        color: #ffc43e;
      }

      h4 {
        color: #ffc43e;
        font-size: 16px;
      }

      span {
        color: $secondary-color;
        font-size: 14px;
        display: block;
        margin-bottom: 10px;
        word-break: break-all;
      }
    }

    .useful-links {
      h5 {
        font-size: 18px;
        color: $white;
        margin-bottom: 25px;
        display: block;
        text-transform: capitalize;
      }

      ul {
        position: relative;
        z-index: 1;
        margin: 0;
        padding: 0;

        li {
          list-style: none;
          display: flex;
          margin-bottom: 10px;

          .icon {
            color: $secondary-color;
            margin-top: 2px;
          }

          a {
            display: block;
            color: $white;
            font-size: 14px;
            font-weight: 400;
            text-transform: capitalize;
          }
        }
      }
    }

    .about {
      h5 {
        font-size: 18px;
        color: $white;
        margin-bottom: 25px;
        display: block;
        text-transform: capitalize;
      }

      span {
        color: $secondary-color;
        font-size: 14px;
        display: block;
        margin-bottom: 10px;
        line-height: 1.5;
        letter-spacing: 0.5px;
      }
    }

    .keywords-container {
      width: 100%;

      .link-container {
        display: flex;
        flex-wrap: wrap;
        a {
          text-decoration: none;
        }
        .keyword-link {
          color: $secondary-color;
          margin-right: 10px;
          padding-right: 10px;
          border-right: 1px solid $secondary-color;
        }
      }
    }
  }

  .copywrite-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $bombay;

    p {
      font-size: 14px;
      color: $bombay;
      margin-bottom: 0;
      line-height: 1.7;
      padding-top: 5px;

      .heart-icon {
        color: $secondary-color;
      }

      a {
        color: $bombay;
        font-size: 14px;
        text-decoration: none;

        &:hover {
          color: $white;
          transition-duration: 500ms;
        }
      }
    }

    .social-info {
      padding-top: 18px;

      a {
        text-decoration: none;
      }
    }
  }
}

@media (max-width: 375px) {
  .main-footer-area {
    height: 90rem;

    .footer-grid-container {
      width: 80%;
      margin: 0 auto;

      .contact-info {
        .logo {
          width: 100%;
          max-width: 100%;
          height: auto;
        }

        h4 {
          font-size: 14px;
        }
      }
    }
  }
}
