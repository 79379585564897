@import './variable.scss';

$primary-color: map_get($base-colors, 'primary-color');
$secondary-color: map_get($base-colors, 'text-color');
$light_gray: map_get($font-colors, 'light-gray');
$dark_gray: map_get($font-colors, 'dark-gray');
$white: map_get($font-colors, 'white');
$alto: map_get($font-colors, 'alto');

.mobile-search-box {
  display: none;
}

.search-box {
  width: 40%;
  position: relative;

  .search-text {
    width: 100%;
    color: $white;
    border-radius: 8px;
    border: 1px solid $white;
    background-color: $white;
    border-radius: 3px;
    //padding: 0 10px;
    transition: width 0.4s ease-in-out;
    //transform: translateX(-5%);
    transition: opacity 0.25s, transform 2s;

    .MuiOutlinedInput-input {
      padding: 10.5px 14px;
    }

    .icon {
      color: $primary-color;

      &:hover {
        color: $secondary-color;
        cursor: pointer;
      }
    }
  }

  ul {
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 15;
    background-color: #fff;
    width: 100%;
    max-height: 275px;
    overflow: auto;
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
      padding: 5px;

      &:hover {
        color: $secondary-color;
        cursor: pointer;
      }
    }
  }
}

.search-icon-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 35%;

  .search-icon {
    color: $primary-color;

    &:hover {
      color: $secondary-color;
      cursor: pointer;
    }
  }
}

@media (min-width: 280px) and (max-width: 480px) {
  .mobile-search-box {
    display: block;
    width: 95%;
    margin: 0 auto;
  }
}
