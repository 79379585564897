.layout-container {
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  //overflow: hidden;

  .content-wrap {
    padding-bottom: 30rem; /* Footer height */
    //margin-top: -30px;
    min-height: 100vh;
    background-color: #f7f8fa;
    display: flex;
    flex: 1;
    justify-content: center;
    //align-items: center;
  }
}

@media (max-width: 880px) {
  .layout-container {
    .content-wrap {
      padding-bottom: 90rem;
    }
  }
}
