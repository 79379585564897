// fonts
$fonts: (
  'heading': 'heading-font',
  'content': 'Open Sans',
  'fallBackContent': sans-serif,
);

$font-colors: (
  'dark-gray': #55595d,
  'gray': #686868,
  'light-gray': #919191,
  'white': #ffffff,
  'alto': #d3d3d3,
  'lightning-yellow': #fdc312,
  'blue-bayoux': #525c7d,
  'william': #406775,
  'bombay': #afb4bf,
);

/* Colors */
$base-colors: (
  'primary-color': #202f5e,
  'text-color': #ef7f1b,
);
